body,
input,
textarea,
button {
  font-family: Aspira, Arial, sans-serif;
  font-size: 16px;
  line-height: 1;
}

.t-matrix {
  font-family: "matrix-ii", Times, "Times New Roman", serif;
}

h1,
h2,
h3,
h4 {
  font-family: "matrix-ii", Times, "Times New Roman", serif;
  font-weight: 800;
  line-height: 1.125;
}

h1 {
  font-size: 2em;
}

h2 {
  font-size: 1.75em;
}

h3 {
  font-size: 1.25em;
}

h4 {
  font-size: 1.125em;
}

p,
li,
input,
textarea {
  line-height: 1.25;
}

.readmore {
  text-align: right;

  a::after {
    content: " ›";
  }
}

@include mq(700px) {
  h1 {
    font-size: 3em;
  }

  h2 {
    font-size: 2.25em;
  }

  h3 {
    font-size: 1.625em;
  }

  h4 {
    font-size: 1.125em;
  }
}

@include mq(1000px) {
  h1 {
    font-size: 4em;
  }

  h2 {
    font-size: 2.85em;
  }

  h3 {
    font-size: 2em;
  }

  h4 {
    font-size: 1.125em;
  }
}
