/**
 ** Modal
 */

/* @url http://tympanus.net/codrops/2013/06/25/nifty-modal-window-effects/ */
.md-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  width: auto;
  height: auto;
  z-index: 20001;
  visibility: hidden;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

.md-show {
  visibility: visible;
}

.md-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  visibility: hidden;
  top: 0;
  left: 0;
  z-index: 20000;
  opacity: 0;
  transition: all 0.3s;
}

.md-show ~ .md-overlay {
  opacity: 1;
  visibility: visible;
}

.md-effect-7 .md-content {
  -webkit-transform: translateY(-200%);
  -moz-transform: translateY(-200%);
  -ms-transform: translateY(-200%);
  transform: translateY(-200%);
  -webkit-transition: all .6s;
  -moz-transition: all .6s;
  transition: all .6s;
  opacity: 0;
}

.md-show.md-effect-7 .md-content {
  -webkit-transform: translateY(0%);
  -moz-transform: translateY(0%);
  -ms-transform: translateY(0%);
  transform: translateY(0%);
  opacity: 1;
}


/**
 ** City mixer
 */

$black: #141212;
$green: #44e234;
$red: #ed1b2f;
$gray: #f6f7ee;

$border-radius: calc(10em / 16);

@function em($val, $context) {
  @return $val / $context em;
}

@mixin arrow-up($sz, $clr) {
  width: 0;
  height: 0;
  border-left: $sz solid transparent;
  border-right: $sz solid transparent;
  border-bottom: $sz solid $clr;
}

@mixin arrow-down($sz, $clr) {
  width: 0;
  height: 0;
  border-left: $sz solid transparent;
  border-right: $sz solid transparent;
  border-top: $sz solid $clr;
}

@mixin arrow-right($sz, $clr) {
  width: 0;
  height: 0;
  border-top: $sz solid transparent;
  border-bottom: $sz solid transparent;
  border-left: $sz solid $clr;
}

@mixin arrow-left($sz, $clr) {
  width: 0;
  height: 0;
  border-top: $sz solid transparent;
  border-bottom: $sz solid transparent;
  border-right: $sz solid $clr;
}

/*
 * general
 */
html {
  height: 100%;
  width: 100%;
  background-size: cover;
  color: $black;
}

body.citymixer {
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.clearfix::after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

//image replacement
.ir {
	text-indent: -9999em;
	overflow: hidden;
}

#citymixer {
  position: relative;
  width: calc(((100em/16 + 1em) * 5) + 0.6em);
  margin: 0 auto;
  opacity: 0;
  transition: opacity 0.75s ease-in;
  font-size: 17px;
  font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", sans-serif;
  font-weight: 300;
  &.active {
    opacity: 1;
  }

  h1 {
    padding: 0.5em 0;
    margin: 0;
    color: $gray;
    font-size: 2em;
    text-shadow: 0 0 1px $black;
    text-align: center;
    visibility: hidden;
    img {
      height: 2em;
    }
  }
}
#citymixer, .md-modal {
  p, ul, ol {
    margin-bottom: 1em;
    letter-spacing: calc(1em / 18);
  }
  
  ul {
    list-style: disc outside;
  }
  
  ol {
    list-style: decimal outside;
  }
  
  strong {
    color: $red;
  }
  
  .centered {
    text-align: center;
  }
  
  .rounded {
    border-radius: $border-radius;
  }
}

#mixer, #controls {
  margin: 0 auto;
}

#mixer {
  border-radius: $border-radius;
  background: $gray;
  padding: 0.3em;
}

$channelWidth: calc(100em / 16);

.channel {
  border-radius: $border-radius;
  width: $channelWidth;
  margin: 0.5em;
  float: left;
  height: calc(550em / 16 - 30em / 16);
  box-shadow: 0 0 0 1px #333;
  position: relative;
  overflow: hidden;.lock {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $black;
    opacity: 0.4;
    display: none;
  }
  
  &.locked {
    > div, .activestate {
      opacity: 0.2;
    }.lock {
      display: block;
      opacity: 0.3;
      z-index: 2;
    }.activestate {
      background: transparent url('../images/citymixer/loading.gif') no-repeat 50% 50% !important;
    }
  }
  
  .selectwrapper {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    right: 0;
    height: 2em;
    line-height: 2em;
    background: darken($gray, 20%);
    span {
      display: block;
    }
    > span {
      span {
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 0.8em;
        padding: 0 0 0 1em;
        text-transform: capitalize;
        width: calc($channelWidth - 1em);
      }
    }
    &::after {
      content: '';
      @include arrow-down(0.5em, $black);
      position: absolute;
      right: 0.375em;
      top: 0.825em;
      z-index: 1;
      pointer-events: none;
    }
    &:hover::after {
      @include arrow-down(0.5em, $red);
    }
    select {
      width: 100%;
      overflow: hidden;
      display: block;
      height: 100%;
      z-index: 2;
      -webkit-appearance: none;
    }
  }
  .activestate {
    display: block;

    width: 80%;
    height: calc(32em / 16);
    position: absolute;
    left: 10%;
    top: calc(30em / 16);
    top: calc(490em / 16 - 30em / 16);
    &.active {
      background-image: url('../images/citymixer/channel-active.png');
    }
  }

  @for $num from 0 through 3 {
    &#channel#{$num} {
      .activestate {
        background: transparent url('../images/citymixer/pie-#{$num}-inactive.png') no-repeat 50% 50%;&.active {
          background: transparent url('../images/citymixer/pie-#{$num}.png') no-repeat 50% 50%;
        }
      }
    }
  }

  &#channelmaster {
    background: transparent url('../images/citymixer/branding.png') no-repeat 50% 1em;
    background-size: calc($channelWidth - 3.5em);
    .activestate {
      background: transparent url('../images/citymixer/pie-master-inactive.png') no-repeat 50% 50%;&.active {
        background: transparent url('../images/citymixer/pie-master.png') no-repeat 50% 50%;
      }
    }
  }
  
  .panningwrapper {
    position: absolute;
    top: calc(70em / 16 - 30em / 16);
    left: calc(10em / 16);
    padding-top: 0.3em;
    background: transparent url('../images/citymixer/panning.png') no-repeat 50% 0;
    background-size: 100%;
    .panning  {
      width: calc(80em / 16);
      height: calc(8em / 16);
      border-radius: calc(5em / 16);
      background: #8a8a8a;
      cursor: pointer;
      -webkit-appearance: none;
      border: 0;
      outline: none;
      padding: 0;
      margin: calc(6em / 16) 0;
    }
    .panning::-moz-range-track  {
      border: none;
      background: transparent;
    }
    .panning::-ms-tooltip  {
      display: none;
    }
    .panning::-webkit-slider-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
      -webkit-appearance: none;
    }
    .panning::-ms-track  {
      width: calc(80em / 16);
      height: 6px;
      border-radius: calc(5em / 16);
      background: #8a8a8a;
      color: transparent;
      border: 0;
    }
    .panning::-moz-range-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
    }
    .panning::-ms-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
    }
    .panning:focus::-webkit-slider-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .panning:focus::-moz-range-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .panning:focus::-ms-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .panning:active::-webkit-slider-thumb  {
      border-color: #000000;
      background: #ffffff;
    }
    .panning:active::-moz-range-thumb  {
      border-color: #000000;
      background: #ffffff;
    }
    .panning:active::-ms-thumb  {
      border-color: #000000;
      background: #ffffff;
    }
    .panning::-moz-range-progress  {
      display: none;
      height: 100%;
      background: transparent;
    }
    .panning::-ms-fill-lower  {
      display: none;
      height: 100%;
      background: transparent;
    }
  }
  .volumewrapper {
    position: absolute;
    left: 0;
    right: 0;
    height: calc(360em / 16 + 1em);
    top: calc(100em / 16 - 30em / 16);
    background: transparent url('../images/citymixer/volume.png') no-repeat, calc(($channelWidth / 2) + 0.1em) 0.4em;
    background-size: calc(38em / 16), calc(368em / 16);
    .volume {
      transform: rotate(270deg) translateX(calc(-1 * (360em / 16))) translateY(calc($channelWidth / 2 - 6em / 16));
      transform-origin: top left;
    }
    .volume  {
      width: calc(360em / 16);
      height: calc(10em / 16);
      border-radius: calc(5em / 16);
      background: #8a8a8a;
      -webkit-appearance: none;
      cursor: pointer;
      border: 0;
      outline: none;
      padding: 0;
      margin: calc(6em / 16) 0;
    }
    .volume::-moz-range-track  {
      border: none;
      background: transparent;
    }
    .volume::-ms-tooltip  {
      display: none;
    }
    .volume::-webkit-slider-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
      -webkit-appearance: none;
    }
    .volume::-ms-track  {
      width: calc(360em / 16);
      height: calc(10em / 16);
      border-radius: calc(5em / 16);
      background: #8a8a8a;
      color: transparent;
      border: 0;
    }
    .volume::-moz-range-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
    }
    .volume::-ms-thumb  {
      width: 1.6em;
      height: 1.6em;
      border: 1px solid #666666;
      border-radius: 50%;
      background: #efefef;
    }
    .volume:focus::-webkit-slider-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .volume:focus::-moz-range-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .volume:focus::-ms-thumb  {
      border-color: #333333;
      background: #fafafa;
    }
    .volume:active::-webkit-slider-thumb  {
      border-color: $red;
      background: #ffffff;
    }
    .volume:active::-moz-range-thumb  {
      border-color: $red;
      background: #ffffff;
    }
    .volume:active::-ms-thumb  {
      border-color: $red;
      background: #ffffff;
    }
    .volume::-moz-range-progress  {
      display: block;
      height: 100%;
      background: #333333;
      border-radius: calc(5em / 16);
    }
    .volume::-ms-fill-lower  {
      display: block;
      height: 100%;
      background: #333333;
      border-radius: calc(5em / 16);
    }
  }.title {
    display: none;
  }
}


/**
 * CONTROLS
 */
 #controls {
  margin: 1.5em auto;
  text-align: center;
  width: 100%;
  position: absolute;
  top: 0.5em;

  a {
    border-radius: $border-radius;
    display: block;
    margin: 0.2em;
    height: calc(40em / 16);
    width: calc(40em / 16);
    text-decoration: none;
    text-align: center;
    line-height: calc(40em / 16);
    background: $black;
    border: 1px solid lighten($black, 20%);
    color: $gray;
    font-size: 1em;
    float: left;
    opacity: 0.8;
    &.ir {
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: 70%;
    }
    &.share {
      float: right;
      background-image: url('../images/citymixer/facebook.png');
    }
    &.info {
      float: right;
      background-image: url('../images/citymixer/help.png');
      background-size: 60%;
    }
    &.reset {
      background-image: url('../images/citymixer/reset.png');
    }
    &.stop {
      background-image: url('../images/citymixer/stop.png');
    }
    &:hover {
      opacity: 1;
    }
    &.active {
      background-color: $red;
      box-shadow: 0 0, calc(5em / 16) $red;
    }
  }
}

/**
 * PARTNERS
 */
#partners {
  background: $gray;
  border-radius: $border-radius;
  ul {
    margin: 1em 0;
    padding: 0.75em 0 0.5em 0;
    text-align: center;
  }
  li {
    display: inline-block;
    padding: 0 1em;
  }
  img {
    height: calc(50em / 16);
    opacity: 0.5;&:hover {
      opacity: 1;
    }
  }
}


/**
 * OVERLAY
 */
.md-overlay {
  background: rgba(0, 0, 0, 0.45);
}
.md-content {
  position: relative;
}
.md-modal {
  .md-content {
    border-radius: $border-radius;
    width: 30em;

    background: $gray;
    padding: 2em 3em;
    font-size: 1.1em;

    h1 {
      color: $black;
      text-align: center;
      margin: 0 0 0.5em;
      font-size: 2.4em;

      span {
        font-size: 0.4em;
        display: block;
      }
    }

    a.md-close--button {
      border-radius: $border-radius;
      padding: 0.75em;
      color: #fff;
      background: $red;
      text-decoration: none;
      text-transform: uppercase;
      font-size: 0.9em;&:hover {
        background: darken($red, 10%);
      }
    }
    p, ol, li {
      margin-bottom: 1em;
      line-height: 1.48;
    }

    p:last-of-type {
      margin: 2em 0 0 0;
    }
    .md-content-body {
      display: none;

      ol {
        list-style-position: inside;
        max-height: 15em;
        overflow: auto;
      }
    }
    .md-content-body--active {
      display: block;
    }
    .md-language-selector {
      padding: 0;
      margin: 0;
      position: absolute;
      right: -3em;
      top: 0.5em;
      width: 2.5em;
      li, li a {
        display: block;
        width: 2.5em;
        height: 2.5em;
        line-height: 2.5em;
        text-align: center;
      }
      li {
        margin-bottom: 0.5em;
        a {
          border-radius: $border-radius;
          text-decoration: none;
          color: $gray;
          background: $black;
          border: 1px solid lighten($black, 20%);&.active {
            background: $red;
            font-weight: 700;
          }
        }
      }
    }
  }
}


#md-incompatible {
  display: none;
}
.md-close--topright {
  font-size: 40px;
  position: absolute;
  right: 0;
  top: -0.25em;
  padding: 0.2em;
  text-decoration: none;
  color: $red;
  opacity: 0.8;
  &:hover {
    opacity: 1;
  }
}

@media (max-width: 850px) {
  #citymixer, .md-modal {
    font-size: 14px;
  }
  .md-modal {
    width: 90%;
    font-size: 14px;
    .md-content {
      width: 80%;
      margin: 0 auto;
    }
  }
}

@media (max-width: 680px) {
  #citymixer {
    font-size: 12px;
  }
  .md-modal {
    font-size: 10px;
  }
}

@media (max-width: 510px) {
  #citymixer, .md-modal {
    font-size: 10px;
  }
  .md-content {
    width: 70%;
  }
}


@media (min-width: 1250px) {
  #partners {
    position: absolute;
    bottom: 8em;
    right: -10em;
    width: 7em;
    ul {
      padding: 0;
      margin: 2em 0;
    }
    li {
      display: block;
      text-align: center;
      margin: 1em 0;
      img {
        max-width: 80%;
        height: auto;
      }
    }
  }
}
