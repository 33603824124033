.icon {
  position: relative;
  top: 0.15em;
  display: inline-block;
  width: 1.25em;
  height: 1em;
  margin-right: 0.5em;
  background-image: url("../images/icon-aifoon.svg");
  background-size: contain;
}

.icon-vimeo {
  background-image: url("../images/icon-vimeo.svg");
}

.icon-youtube {
  background-image: url("../images/icon-youtube.svg");
}

.icon-soundcloud {
  background-image: url("../images/icon-soundcloud.svg");
}

.icon-freesound {
  background-image: url("../images/icon-freesound.svg");
}

.icon-facebook {
  background-image: url("../images/icon-facebook.svg");
}

.icon-video-play {
  width: 2em;
  height: 1.375em;
  background-image: url("../images/icon-video-play.svg");
}

.icon-youtube-full {
  width: 4.375em;
  height: 1em;
  background-image: url("../images/icon-youtube-full.svg");

  &.icon-white {
    background-image: url("../images/icon-youtube-full-white.svg");
  }
}

.icon-vimeo-full {
  width: 3.125em;
  height: 0.875em;
  background-image: url("../images/icon-vimeo-full.svg");
  &.icon-white {
    background-image: url("../images/icon-vimeo-full-white.svg");
  }
}
