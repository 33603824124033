$litebox-bg: rgba(#000, 0.9);
$litebox-gutter-l: 2 * $margin-large;
$litebox-gutter-s: $margin-large;

.litebox-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
}

.litebox-closed {
  display: none;
  opacity: 0;
}

.litebox-open {
  display: block;
  background: $litebox-bg;
  opacity: 1;
  animation: 0.2s linear 0s 1 fadeIn;
}

.litebox-inner {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: $litebox-gutter-l;
  background: transparent url("../images/icon-loader.gif") no-repeat 50% 50%;
  background-size: $litebox-gutter-l $litebox-gutter-l;

  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    margin: 0 auto;
    opacity: 0;

  }

  iframe {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
  }

  .litebox-image-ready,
  .litebox-video-ready {
    opacity: 1;
    animation: 0.5s linear 0s 1 fadeIn;
  }

}

.litebox-button-close {
  position: absolute;
  top: $litebox-gutter-s;
  right: $litebox-gutter-s;
  width: $litebox-gutter-l;
  height: $litebox-gutter-l;
  overflow: hidden;
  text-align: -9999px;
  background-color: transparent;
  border: 0;

  .icon {
    display: block;
    width: 100%;
    height: 100%;
  }
}
