/* COLOURS */

$black:     #282828;
$blackish:  #3c3c3c;
$whitish:   #e8e8e8;
$white:     #fbfbfb;
$highlight: #80ffb6;
$red:       #ff6a61;

/* BASIC COLOUR STYLES */

h1,
h2,
h3,
h4,
h5 {
  color: $blackish;
}

:focus {
  outline: 0;
  box-shadow: 1px 1px 2px 0 rgba($highlight, 0.3);
}

.theme-highlight *:focus {
  box-shadow: 1px 1px 2px 0 rgba($black, 0.3);
}

a {
  color: inherit;
  text-decoration: none;
}

.links-styled a {
  box-shadow: inset 0 -2px 0 0 $highlight;
}

.navbar .is-active-link {
  border-bottom-color: $highlight;
}

input[type="submit"],
.button {
  color: $black;
  background-color: $highlight;
  border-color: $highlight;
}

input[disabled] {
  opacity: 0.5;  
}

.no-touch {
  .no-hover-background:hover {
    background-color: inherit !important;
  }

  .no-hover-outline:hover {
    outline: 0 !important;
  }

  .no-hover:hover {
    background-color: inherit !important;
    outline: 0 !important;
  }

  input[type="submit"],
  .button {
    &:hover {
      opacity: 0.75;
    }
  }

  input[disabled]:hover {
    opacity: 0.5;
  }
}

.navbar, .site-footer {
  background-color: $white;
}

/* THEME SPECIFIC */

.theme-default,
.theme-light {
  color: $black;
  background-color: $white;
  border-color: $black;
}

.theme-dark {
  color: $white;
  background-color: $black;
  border-color: $white;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $white;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  textarea {
    color: $white;
    border-color: $white;
  }

}

.theme-highlight {
  color: $black;
  background-color: $highlight;
  border-color: $black;

  h1,
  h2,
  h3,
  h4,
  h5 {
    color: $black;
  }

  input[type="text"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="url"],
  textarea {
    color: $black;
    border-color: $black;
  }

  input[type="submit"],
  .button {
    color: $highlight;
    background-color: $black;
    border-color: $black;
  }
}

.error-message {
  color: $red;
}

.input-has-error {
  color: $red !important;
  border-color: $red !important;
}

/* CARD TEXTURES */

.card {
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.card:nth-child(3n) { background-image: url("../images/pcbg1-s.png"); }
.card:nth-child(3n+1) { background-image: url("../images/pcbg2-s.png"); }
.card:nth-child(3n+2) { background-image: url("../images/pcbg3-s.png"); }

.card-double:nth-child(3n) { background-image: url("../images/pcbg1-l.png"); }
.card-double:nth-child(3n+1) { background-image: url("../images/pcbg2-l.png"); }
.card-double:nth-child(3n+2) { background-image: url("../images/pcbg3-l.png"); }

.theme-dark.card:nth-child(3n) { background-image: url("../images/pcbgbk1-s.png"); }
.theme-dark.card:nth-child(3n+1) { background-image: url("../images/pcbgbk2-s.png"); }
.theme-dark.card:nth-child(3n+2) { background-image: url("../images/pcbgbk3-s.png"); }

.theme-dark.card-double:nth-child(3n) { background-image: url("../images/pcbgbk1-l.png"); }
.theme-dark.card-double:nth-child(3n+1) { background-image: url("../images/pcbgbk2-l.png"); }
.theme-dark.card-double:nth-child(3n+2) { background-image: url("../images/pcbgbk3-l.png"); }

.theme-shadow {
  box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
}

/* RESPONSIVE OVERRIDES */

@include mq(1000px) {
  body {
    background-color: $whitish;
    background-position: 50% $navbar-height;
  }

  .theme-texture-large {
    background-image: url("../images/pcbg-large.png");
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

}

