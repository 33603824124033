.margin-box {
  margin: $margin-small;

  @include mq(700px) {
    margin: $margin-large;
  }
}

.padding-box {
  padding: $margin-small;

  @include mq(700px) {
    padding: $margin-large;
  }
}

/* === grid mixins === */

@mixin columnWidth($columns, $gap) {
  width: calc((100% - (#{$columns} - 1) * #{$gap}) / #{$columns});
}

@mixin doubleColumnWidth($columns, $gap) {
  width: calc((100% - (#{$columns} - 1) * #{$gap}) / #{$columns} * 2 + #{$gap});
}

@mixin columnWidths($columns, $gap) {
  .grid-gutter {
    width: $gap;
  }

  .grid-item-single {
    @include columnWidth($columns, $gap);
  }

  .grid-item-double {
    @include doubleColumnWidth($columns, $gap);
  }
}

/* === flexbox grid settings === */

@include mq(700px) {
  .flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .grid-item {
      margin: 0 calc($margin-large / 2) calc($margin-large / 2);
    }
  }
}

/* === masonry grid settings === */

@include mq(700px) {
  .grid-container {
    width: calc(100% - 2 * #{$margin-large});
    margin: $margin-large;
  }

  .grid-item {
    margin-bottom: $margin-large;
  }

  @include columnWidths(2, $margin-large);
}

@include mq(950px) {
  @include columnWidths(3, $margin-large);

  .grid-count-1 {
    width: 33.3333%;
    margin-right: auto;
    margin-left: auto;

    @include columnWidths(1, $margin-large);
  }

  .grid-count-2 {
    width: 66.6667%;
    margin-right: auto;
    margin-left: auto;

    @include columnWidths(2, $margin-large);
  }
}

@include mq(1200px) {
  @include columnWidths(4, $margin-large);

  .grid-count-1 {
    width: 25%;
  }

  .grid-count-2 {
    width: 50%;
  }

  .grid-count-3 {
    width: calc(75% - 2 * #{$margin-large});

    margin-right: auto;
    margin-left: auto;
    @include columnWidths(3, $margin-large);
  }
}

/* === css grid fallback settings ===
 *
 * CSS grid fallback in case Masonry is not loading
 * We want content to flow in vertical columns,
 * AND span columns, which is currently not possible in
 * neither flexbox nor CSS grid
 */

.no-js {
  @include mq(700px) {
    .grid-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: $margin-large;
    }

    .grid-item-double {
      grid-column: auto / span 2;
    }
  }

  @include mq(950px) {
    .grid-container {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @include mq(1200px) {
    .grid-container {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
