input[type="text"],
input[type="email"],
input[type="password"],
input[type="tel"],
input[type="url"],
textarea {
  box-sizing: border-box;
  padding: 0.5em 0.5em 0.4em;
  background: transparent;
  border-style: solid;
  border-width: 1px;
}

input[type="submit"] {
  padding: 0.5em 1em 0.4em;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;

  &[disabled] {
    cursor: default;
  }
}
