.card-link {
  display: block;
}

.card-content-title,
.card-content-text {
  h2,
  h3 {
    font-size: 1.5em;
  }

  > h2,
  > h3 {
    margin-top: $margin-large;
  }
}

.readmore {
  margin-bottom: 0;
}

@supports (mix-blend-mode: multiply) {
  .theme-highlight .card-content-image {
    mix-blend-mode: multiply;
  }

  .theme-light .card-content-image,
  .theme-dark .card-content-image,
  .no-touch .theme-highlight .card-content-image:hover {
    mix-blend-mode: normal;
  }
}

.card-content-image {
  display: block;
  width: 100%;
  height: auto;
  filter: grayscale(100%) contrast(130%); // TO BE REMOVED ONCE IMGIX IS ACTIVE ?
}

.no-touch .card-content-image:hover {
  filter: grayscale(0) contrast(100%);
}

.card-video {
  position: relative;
}

.card-video-provider {
  position: absolute;
  top: calc( $margin-small / 2);
  right: calc($margin-small / 2);
  z-index: 2;
  display: block;
  margin: 0;
}

.card-video-meta {
  position: absolute;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  box-sizing: border-box;
  padding: $margin-small;
  color: $white;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 30%,  rgba(0, 0, 0, 0.9) 100%);
}


@include mq(700px) {
  .card-image-title {
    .card-inner {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .card-content-title {
      width: 50%;
    }

    .card-content-image {
      max-width: 50%;
      max-height: 33.3333vh;
      margin: 0;
    }
  }
}

@include mq(1000px) {
  .card-double {
    .card-content-title {
      h2, h3 {
        margin: 0 0 $margin-large;
      }
    }
    .card-content-title,
    .card-content-text {
      font-size: 1.125em;

      h2,
      h3 {
        font-size: 2em;
      }
    }
  }

  .card-image-title {
    .card-content-image {
      width: 33.3333%;
    }

    .card-content-title {
      width: 66.6667%;
    }
  }
}
