@font-face {
  font-family: 'Aspira';
  src: url('../fonts/Aspira-Bold.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Aspira-Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Aspira-Bold.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Aspira-Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Aspira-Bold.svg#0d0036c6dfae097b05b598321bc990bb') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Aspira';
  src: url('../fonts/Aspira-Bold-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Aspira-Bold-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Aspira-Bold-Italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Aspira-Bold-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Aspira-Bold-Italic.svg#ef262614b67cdc85d86f8f62e1b6bfb8') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  700;
}

@font-face {
  font-family: 'Aspira';
  src: url('../fonts/Aspira-Medium.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Aspira-Medium.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Aspira-Medium.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Aspira-Medium.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Aspira-Medium.svg#769fa48b228fef7ece4afdea034ff03a') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'Aspira';
  src: url('../fonts/Aspira-Medium-Italic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Aspira-Medium-Italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/Aspira-Medium-Italic.woff') format('woff'), /* Modern Browsers */
       url('../fonts/Aspira-Medium-Italic.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/Aspira-Medium-Italic.svg#d56b64e37aa604f477f3b2e7ea37e361') format('svg'); /* Legacy iOS */
       
  font-style:   italic;
  font-weight:  400;
}
