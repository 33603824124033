@keyframes slideOpen {
  from { max-height: 0; }
  to { max-height: 100vh; }
}

@keyframes slideShut {
  from { max-height: 100px; }
  to { max-height: 0; }
}

.navbar {
  display: table;
  width: 100%;
  height: $navbar-height;
  box-sizing: border-box;
  padding: 0 $margin-small;
  line-height: 1;
}

$logo-top-margin: calc(($navbar-height - 42px) / 2);
$logo-bottom-margin: calc($logo-top-margin + 6px);

.navbar-logo {
  display: block;
  height: calc($navbar-height - 14px - 20px);
  padding: $logo-top-margin $margin-large $logo-bottom-margin 0;
}

.navbar-logo-img {
  display: block;
  width: auto;
  height: 100%;
}

.navbar-menu {
  float: right;
  width: 100%;
  overflow: hidden;
}

.no-js .navbar-menu {
  max-height: 100vh;
}

.menu-open {
  animation: 750ms ease-in 0s 1 slideOpen forwards;
}

.menu-closed {
  animation: 300ms ease-in 0s 1 slideShut forwards;
}

.navbar-menu-list {
  padding: 0;
  margin: $margin-large 0;
  text-align: center;
}

.navbar-menu-item {
  display: inline-block;

  a {
    display: block;
  }

  .is-active-link {
    margin-bottom: -2px;
    cursor: default;
    border-bottom-style: solid;
    border-bottom-width: 2px;
  }
}

.menu-item-main {
  font-size: 1.125em;
}

.menu-item-external {
  font-size: 0;

  .icon {
    font-size: 1rem;
  }
}

@include mq(699px, max-width) {
  .menu-item-main {
    display: block;
    margin-bottom: 1em;

    .navbar-menu-item-link {
      display: inline-block;
    }
  }

  .navbar::after {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    height: $navbar-height;
    padding: 0 $margin-small 0 calc($margin-small + 20px);
    line-height: $navbar-height + 2px;
    text-align: right;
    content: "menu";
    background-image: url("../images/icon-menu.svg");
    background-repeat: no-repeat;
    background-position: $margin-small 50%;
    background-size: 13px 13px;
  }
}

@include mq(700px) {
  .navbar {
    padding: 0 $margin-large;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  }

  .navbar-logo {
    display: table-cell;
  }

  .navbar-menu {
    display: table-cell;
    float: none;
    width: 100%;
    max-height: none;
    padding-top: 4px;
    vertical-align: middle;
  }

  .navbar-menu-list {
    display: table-cell;
    height: calc($navbar-height - 4px);
    margin: 0;
    text-align: left;
    white-space: nowrap;
    vertical-align: middle;
  }

  .navbar-menu-main {
    width: 100%;
  }

  .navbar-menu-language {
    padding-left: $margin-large;
  }

  .navbar-menu-item {
    margin: 0 0.5em;

    .is-active-link {
      margin-bottom: -3px;
      border-bottom-width: 3px;
    }
  }

  .menu-item-language {
    margin: 0 0 0 0.25em;
    font-size: 0.875em;
  }

}

@include mq(1350px) {
  .navbar-menu-item {
    .is-active-link {
      margin-bottom: -4px;
      border-bottom-width: 4px;
    }
  }

  .menu-item-main {
    margin: 0 1em;
  }

  .menu-item-external {
    font-size: 0.875em;

    .icon {
      font-size: 1em;
    }
  }
}
