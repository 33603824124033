.project-section {
  padding: $margin-large;
}

.project-set {
  margin-bottom: $margin-small;
}

.intro-image {
  display: block;
  max-width: 100%;
  height: auto;
  margin: 0 auto;
}

@include mq(700px) {

  .project-set {
    margin-bottom: $margin-large;
  }
}

@include mq(950px) {
  .project-set {
    margin-bottom: calc($margin-large * 2);
  }
}

@include mq(1200px) {
  .project-set {
    margin-bottom: calc($margin-large * 3);
  }
}
