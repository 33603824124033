.site-footer {
  margin-top: $margin-large;
}

.site-footer-inner {
  padding-top: $margin-large;
  margin: 0 $margin-small;
}

.site-footer-partner {
  display: inline-block;
  height: 40px;
  margin-right: $margin-large;

  &:last-child {
    margin-right: 0;
  }

  img {
    display: block;
    width: auto;
    height: 100%;
  }
}

@include mq(700px) {
  .site-footer {
    margin-top: calc(2 * $margin-large);
  }

  .site-footer-inner {
    display: table;
    margin: 0 $margin-large;
  }

  .site-footer-credits,
  .site-footer-partners {
    display: table-cell;
  }

  .site-footer-credits {
    width: 100%;
  }

  .site-footer-partners {
    white-space: nowrap;
  }

}

@include mq(950px) {
  .site-footer {
    margin-top: calc(2 * $margin-large);
  }

  .site-footer-inner {
    padding-top: calc(2 * $margin-large);
  }
}

@include mq(1200px) {
  .site-footer {
    margin-top: calc(3 * $margin-large);
  }

  .site-footer-partner {
    height: 50px;
    margin-right: calc(2 * $margin-large);
  }
}
