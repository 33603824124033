$c1: #80ffb6;
$c2: #1bf4f7;

@mixin theme($class, $colour) {
  #{$class} {
    .links-styled a { box-shadow: inset 0 -2px 0 0 $colour; }

    .navbar .is-active-link,
    .no-touch a:hover {
      background-color: $colour;
      outline: 4px solid $colour;
    }

    .navbar .theme-dark .is-active-link,
    .no-touch .theme-dark a:hover {
      color: $black;
    }

    .no-touch .theme-highlight a:hover {
      color: $colour;
      background-color: $blackish;
      outline: 4px solid $blackish;
    }

    .theme-highlight {
      background-color: $colour;
    }
  }
}

@include theme(".c1", $c1);
@include theme(".c2", $c2);
