.paragraph {
  max-width: 750px;
  margin: $margin-large $margin-small;
}

.paragraph-title {
  max-width: 750px;
  margin-right: $margin-small;
  margin-left: $margin-small;
}

.paragraph-image {
  max-width: 950px;
  img {
    display: block;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 75vh;
    margin: 0 auto;
  }

  .image-caption {
    text-align: center;
  }
}

.paragraph-video iframe {
  width: 100%;
  height: 52.7343vw;
}

@include mq(700px) {
  .paragraph {
    margin: $margin-large;
  }

  .paragraph-title {
    margin-right: $margin-large;
    margin-left: $margin-large;
  }

  .paragraph-introtext {
    font-size: 1.5rem;

    p,
    li {
      line-height: 1.4;
    }
  }

  .paragraph-text {
    font-size: 1.125rem;

    p,
    li {
      line-height: 1.5;
    }
  }
}

@include mq(950px) {
  .paragraph {
    margin: calc(2 * $margin-large) auto;
  }

  .paragraph-title {
    margin-right: auto;
    margin-left: auto;
  }

  .paragraph-text {
    font-size: 1.25rem;
  }

  .paragraph-image-size-normal {
    max-width: 750px;
  }

  .paragraph-image-size-small {
    max-width: 500px;
  }

  .paragraph-video iframe {
    height: 422px;
  }
}

@include mq(1200px) {
  .paragraph {
    margin: calc(3 * $margin-large) auto;
  }

  .paragraph-image-size-full,
  .paragraph-video {
    max-width: 950px;
  }

  .paragraph-video iframe {
    height: 534px;
  }
}
