.card-subscribe-form {
  input {
    margin-bottom: $margin-large;
  }

  .card-subscribe-email {
    width: 100%;
  }
}

@include mq(700px) {
  .card-subscribe-form {
    display: flex;
  }

  .card-subscribe-email {
    flex-grow: 9;
    width: auto;
    margin-right: 1em;
  }
}
